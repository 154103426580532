import { Card, HorizontalScrollView } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";
import { useWorkplaceComments } from "@src/appV2/Reviews/api/useWorkplaceComments";

import { Button } from "../../components/Button";
import { ReviewsSummary } from "../../Review/ReviewsSummary";
import { SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { useWorkplaceModalsDataContext } from "../../ShiftDiscovery/useWorkplaceModalsDataContext";
import { WorkplaceHighlightPills } from "../Highlights/Pills";
import { useWorkplaceReviewHighlights } from "../Highlights/useWorkplaceReviewHighlights";
import { useHideReviewsUIByFacilityId } from "../hooks/useHideReviewsUIByFacilityId";
import { WorkplaceReviewCommentCompactCard } from "../Review/Comment/CompactCard";

export interface WorkplaceHighlightsAndReviewsCardProps {
  workplaceId: string;
}

export function WorkplaceHighlightsAndReviewsCard(props: WorkplaceHighlightsAndReviewsCardProps) {
  const { workplaceId } = props;

  const { generateModalPath } = useWorkplaceModalsDataContext();

  const { data: ratingAggregates, isSuccess: ratingAggregatesIsSuccess } =
    useGetRatingAggregates(workplaceId);

  const { highlights: reviewHighlights, isLoading: isReviewHighlightsLoading } =
    useWorkplaceReviewHighlights({ workplaceId });

  const { facilityIds } = useHideReviewsUIByFacilityId();

  const showComments = !facilityIds.includes(workplaceId);

  const { data: comments, isSuccess: commentsIsSuccess } = useWorkplaceComments({ workplaceId });

  if (!ratingAggregatesIsSuccess || !commentsIsSuccess) {
    return null;
  }

  const { avgRating } = ratingAggregates;

  let buttonText = "View reviews";
  if (comments.length > 0) {
    buttonText = comments.length > 1 ? `View all comments` : "View 1 comment";
  }

  return (
    <Card>
      <CardContent sx={{ padding: 7 }}>
        <ReviewsSummary rating={avgRating ?? 0} />

        <Text semibold variant="body1" sx={{ marginTop: 8, marginBottom: 7 }}>
          Highlights
        </Text>

        <WorkplaceHighlightPills
          highlights={reviewHighlights}
          isLoading={isReviewHighlightsLoading}
        />

        {showComments && (
          <>
            <HorizontalScrollView stretch sx={{ marginTop: 8 }} outerPaddingCompensation={7}>
              {comments.map((comment) => (
                <WorkplaceReviewCommentCompactCard key={comment.id} comment={comment} />
              ))}
            </HorizontalScrollView>

            <Button
              fullWidth
              variant="outlined"
              size="medium"
              sx={{ marginTop: 8 }}
              href={generateModalPath(SHIFT_DISCOVERY_WORKPLACE_REVIEWS_MODAL_PATH, {
                workplaceId,
              })}
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_SCREEN_OPENED, {
                  workplaceId,
                  trigger: "Workplace Profile Modal",
                });
              }}
            >
              {buttonText}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
}
